//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { faAngleRight, faHouse } from '@fortawesome/pro-regular-svg-icons';

export default {
  name: 'breadcrumb',
  props: {
    breadcrumbs: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      faAngleRight,
      faHouse,
    };
  },
  head() {
    if (this.breadcrumbJsonLd) {
      return {
        script: [
          this.breadcrumbJsonLd,
        ],
      };
    }
  },
  computed: {
    breadcrumbJsonLd() {
      if (!this.breadcrumbs.length) {
        return null;
      }

      const list = [];

      let position = 1;

      this.breadcrumbs.forEach((item) => {
        list.push({
          '@type': 'ListItem',
          'position': position,
          'item':
              {
                '@id': this.$link(item.link).url(),
                // use nameSeo if exists for SEO
                'name': _.get(item, 'nameSeo', item.name),
              },
        });

        position += 1;
      });

      return {
        type: 'application/ld+json',
        json: {
          '@context': 'http://schema.org',
          '@type': 'BreadcrumbList',
          'itemListElement': list,
        },
      };
    },
  },
};
